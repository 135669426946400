<div class="container" dir="rtl">
  @if(platform.ANDROID || this.platform.IOS){
  <div class="popup">
    <h1>
      {{ "يمكنك الإنضمام للمقرأة من خلال التطبيق" }}
    </h1>
    <div class="buttons">
      <button (click)="goToApp()" class="app">إلى التطبيق</button>
      <button (click)="download()" class="download">تحميل</button>
    </div>
  </div>
  }
  @if(!platform.ANDROID && !this.platform.IOS)
  {
  <div class="popup">
    <h1 style="margin-bottom: 0;">
      {{ "تطبيق مقارئ غير متاح على متصفح الويب" }}
    </h1>
  </div>
  }
</div>