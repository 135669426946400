import { Platform } from '@angular/cdk/platform';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-share-app',
  standalone: true,
  imports: [],
  templateUrl: './share-app.component.html',
  styleUrl: './share-app.component.scss',
})
export class ShareAppComponent {
  isMobile!: boolean;
  constructor(public platform: Platform, private _router: Router) {}

  goToApp() {
    let category = this._router.url.split('/')[1];
    let token = this._router.url.split('/')[2];
    window.location.href = `Maqare://${category}/${token}`;
  }

  download() {
    if (this.platform.ANDROID)
      window.location.href =
        'https://play.google.com/store/apps/details?id=maqare.kmeet.app';
    if (this.platform.IOS)
      window.location.href =
        'https://apps.apple.com/us/app/maqare-%D9%85%D9%82%D8%A7%D8%B1%D8%A6/id1612866539';
  }
}
